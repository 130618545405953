/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Media } from '@seech/media-ng';
import { ModalService } from '@seech/ux-ng';
import { GalleryCarouselComponent } from '../gallery-carousel/gallery-carousel.component';
import { Guid } from 'typescript-guid';

@Component({
  selector: 'app-lightbox',
  templateUrl: './lightbox.component.html',
  styleUrls: ['./lightbox.component.scss'],
})
export class LightboxComponent implements OnChanges {
  @Input() zoomLevel: number = 0.5;
  @Input() previewLimit: number = 3;
  @Input({ required: true }) media: Media[] = [];
  medias: Media[] = []

  constructor(private modalService: ModalService) { }

  ngOnChanges(changes: SimpleChanges): void {
    const media = changes['media']?.currentValue;
  }
 
  onMediaChatClick(media: Media | Media[]): void {
    const mediaArray = Array.isArray(media) ? media : [media];
    
    const mediaItems: Media[] = mediaArray.map(item => ({
        id: Guid.create().toString(),
        src: item.id,
        type: item.type
    }));
    
    this.modalService.open(GalleryCarouselComponent, {
        data: { 
            selectedMedia: mediaItems[0], 
            mediaItems 
        },
        modalClass: 'modal-dialog-centered modal-lg'
    });
}
  

}
