/* eslint-disable no-self-assign */
/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {
  CharacterLimitDirective,
  ControlsNgModule,
  SelectOption
} from '@seech/controls-ng';
import { IconsNgModule } from '@seech/icons-ng';
import { Media, MediaNgModule, MediaOption } from '@seech/media-ng';
import {
  ChipOverride,
  ChipConfig,
  UxNgModule,
  ModalService,
  ToastService,
} from '@seech/ux-ng';
import { StreamingService, UtilitiesService, Geolocation } from '@seech/core-ng';
import { DetailModule } from '../../modules/general/detail/detail.module';
import { ItemsModule } from '../../modules/general/items/items.module';
import { ComponentsModule } from '../../modules/general/components/components.module';
import { GalleryCarouselComponent } from '../../modules/general/components/gallery-carousel/gallery-carousel.component';
import {
  CONSTANT,
  FILE_TYPES,
  HomeService,
  InvitedProfessionalRequest,
  LookupService,
  ProfessionalSummary,
  RequestedServiceRequest,
  RequestedTaskRequest,
  RequisitionRequest,
  RequisitionResponse,
  RequisitionService,
  ServiceRequest,
  UpdateRequisitionRequest,
  UserProfileResponse,
  UserService,
  StorageService,
} from '@profindar/shared-ng';
import { BreakpointObserver } from '@angular/cdk/layout';
import {
  debounceTime,
  forkJoin,
  map,
  Subject,
  Subscription,
  switchMap,
} from 'rxjs';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { SessionService } from '../../services/session.service';
import { RequisitionInvitationComponent } from '../../modules/features/requisition/components/requisition-invitation/requisition-invitation.component';
import { environment } from '../../../environments/environment';
import { BlobMetaRequest } from 'libs/shared-ng/src/lib/generated/model/blobMetaRequest';
import { FileService } from '../../services/file.service';

@Component({
  selector: 'app-add-edit-requisition',
  templateUrl: './add-edit-requisition.component.html',
  styleUrls: ['./add-edit-requisition.component.scss'],
  standalone: true,
  imports: [
    ItemsModule,
    ControlsNgModule,
    FormsModule,
    ReactiveFormsModule,
    IconsNgModule,
    DetailModule,
    UxNgModule,
    MediaNgModule,
    ComponentsModule,
    RequisitionInvitationComponent,
    CharacterLimitDirective,
  ],
  providers: [TitleCasePipe],
})
export class AddEditRequisitionComponent implements OnInit, OnDestroy {
  cdnBaseUrl = environment.cdnBaseUrl;
  stepperBackground = '#EBF7EE';
  hasMultiSteps = false;

  isFetchingSkills = false;
  isSubmitting = false;
  isProjectCreated = false;

  fileTypes: any[] = FILE_TYPES;

  locationValue!: any;
  taskArrayError = '';
  skillArrayError = '';
  dateError = '';
  endDateError = '';
  datePipe = new DatePipe('en-US');

  acceptedSkillsArrayLength = 5;
  acceptedTaskArrayLength = 50;

  skillsValue: any = '';
  skillsArray: any[] = [];

  taskValue: any = '';
  taskArray: any[] = [];

  overrides: ChipOverride = {
    exclude: ['secondary'],
    replacement: 'info',
  };

  config: ChipConfig = {
    override: this.overrides,
  };

  addEditRequisitionForm!: FormGroup;

  currencyListSize: number = CONSTANT.DEFAULT_PAGINATION_SIZE;
  currencies: any[] = [];
  rateTypes: SelectOption[] = [];

  skillsSuggestions: any[] = [];
  servicesSuggestions: any[] = [];
  tasksSuggestions: any[] = [];
  skillsToolsTechSuggestions: any[] = [];
  mergedSuggestions: any[] = [];

  checkboxValue = true;

  selectCurrencyValue: any = {};
  selectedCurrency!: string;

  selectedPayType!: string;

  minDate: Date;
  minEndDate!: Date;

  noteDescription: string = '';
  noteMaxlength = 1024;
  noteMaxlengthError =
    'Maximum character limit reached. Please reduce the length of your note.';

  selectedDate: any = '';
  selectedEndDate: any = '';
  selectedTime = '';
  selectedEndTime = '';
  rawTimeValue = '';
  isDateValid = false;
  schInputValid = false;
  isMediaAddedInFirstStep = false;
  isMediaAdded = false;
  medias: Media[] = [];
  blobMetaRequests: BlobMetaRequest[] = [];
  //imageUrls: SafeResourceUrl[] = [];
  editingReq = false;

  options: MediaOption[] = [
    { label: 'option 1', id: 'fhd4-39494-4404' },
    { label: 'option 2', id: 'fhd4-39494-54954' },
    { label: 'option 3', id: 'fhd4-39448-49434' },
  ];

  noInvitedProError = '';
  hiredPro!: any;
  professionals: ProfessionalSummary[] = [];
  requestedProfessionalIds: string[] = [];
  selectedProfessionalId!: string;
  isEditing = false;
  isPatchingSkills = false;
  editableRequisition!: RequisitionResponse;
  // initialLoad = false;
  displayIsMobile!: boolean;
  showProItemCard = true;
  isUploading = false;

  private currencySearchTerms$ = new Subject<string>();
  private addSkillSubject = new Subject<string | string[]>();
  private subscription = new Subscription();

  constructor(
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private modalService: ModalService,
    private lookupService: LookupService,
    private requisitionService: RequisitionService,
    private homeService: HomeService,
    private toastService: ToastService,
    private breakpointObserver: BreakpointObserver,
    private titleCasePipe: TitleCasePipe,
    private userService: UserService,
    private sessionService: SessionService,
    private utilityService: UtilitiesService,
    private fileService: FileService,
    private streamingService: StreamingService
  ) {
    const currentDate = new Date();
    this.minDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - 1
    );

    this.subscription.add(
      this.modalService.modalRefComponent().subscribe({
        next: (data) => {
          if (data) {
            if (data.clonableReq) {
              this.isEditing = true;
              this.editableRequisition = data.clonableReq;
            }

            data.professionalId &&
              this.getProfessionalDetailsById(data.professionalId);
          }
        },
      })
    );
  }

  ngOnInit(): void {
    this.addSkillSubject
      .pipe(debounceTime(300))
      .subscribe((skill: any) => this.processAddSkill(skill));

    this.subscription.add(
      this.currencySearchTerms$
        .pipe(
          debounceTime(300) //300 milliseconds of debounce time
        )
        .subscribe((value) => {
          this.getCurrencies(value);
        })
    );

    this.subscription.add(
      this.breakpointObserver
        .observe('(min-width: 992px)')
        .subscribe((state) => {
          this.displayIsMobile = state.matches;
        })
    );

    this.buildAddEditEquisitionForm();
    this.loadDynamicData();

    this.isEditing
      ? this.patchFormValues(this.editableRequisition)
      : this.getLoggedinUserLocation();
  }

  buildAddEditEquisitionForm() {
    this.addEditRequisitionForm = this.fb.group({
      selectedTask: [null, [Validators.required]],
      selectedSkills: [null, [Validators.required]],
      location: [null, [Validators.required]],
      proposedStartDate: [null, [Validators.required]],
      proposedStartTime: [null],
      proposedEndDate: [null],
      proposedEndTime: [null],
      privateBidding: [false],
      description: [null],
      isRemote: [false],
      professionalLocation: [false],
      clientLocation: [false],
      isMobile: [false],
      currency: [null, [Validators.required]],
      budget: [null],
      rateTypeId: [null, [Validators.required]],
      fileUploadControl: [null],
    });

    this.addEditRequisitionForm
      .get('fileUploadControl')!
      .valueChanges.subscribe({
        next: (res: FileList) => {
          if (res) {
            this.isMediaAddedInFirstStep = true;
            this.isMediaAdded = true;
            this.isUploading = true;
            this.stageMediaFiles(res);
          }
        },
      });

    this.addEditRequisitionForm
      .get('proposedStartDate')!
      .valueChanges.subscribe((res) => {
        if (res) {
          this.dateError = '';
          this.updateEndDate();
        }
      });

    this.addEditRequisitionForm
      .get('proposedEndDate')!
      .valueChanges.subscribe((res) => {
        if (res) {
          this.endDateError = '';
        }
      });
  }

  stageMediaFiles(files: FileList) {
    const fileArray = Array.from(files);
    this.fileService
      .stageUpload(fileArray)
      .subscribe({
        next: (responses) => {
          // Map response to BlobMetaRequest objects
          const blobRequestData = responses.map((res) =>
            this.fileService.createBlobMetaRequest(res)
          );
          this.blobMetaRequests = [
            ...this.blobMetaRequests,
            ...blobRequestData,
          ];

          // Map response to Media objects using observables
          const mediaItems = responses.map((item, index) =>
            this.createMediaItem({
              id: index,
              url: item.url,
              type: item.blobType,
              ext: item.extension
            })
          );

          this.medias = [...this.medias, ...mediaItems];
          //mediaItems.forEach((mediaItem) => this.medias.push(mediaItem));
          this.showStepperHeader();
        },
        complete: () => {
          this.isUploading = false;
          //  this.addEditRequisitionForm.reset();
        },
      });
  }

  getBlob(url: string) {
    return this.streamingService.stream(url); //Shared-ng-stream-service
  }

  // Helper function to build BlobMetaRequest object
  createBlobMetaRequest(res: any): BlobMetaRequest {
    return {
      url: res.url,
      modifiedName: res.modifiedName,
      blobType: res.blobType,
      originalName: res.originalName,
      extension: res.extension,
    };
  }

  // Helper function to build Media object
  createMediaItem(item: any): Media {
    return {
      id: item.id,
      src: item.url,
      type: item.type,
      alt: 'uploaded media',
      ext: item.ext
    };
  }

  private getFileArray(files: FileList): Array<File> {
    return Array.from(files); // Simply converts FileList to Array<File>
  }

  updateEndDate() {
    const startDateValue =
      this.addEditRequisitionForm.get('proposedStartDate')!.value;

    if (startDateValue) {
      this.addEditRequisitionForm.get('proposedEndDate')!.setValue(null);
      this.selectedEndDate = '';
      // Update minEndDate based on startDateValue
      const chosenDate = new Date(startDateValue);
      this.minEndDate = new Date(
        chosenDate.getFullYear(),
        chosenDate.getMonth(),
        chosenDate.getDate() - 1
      );
    }
  }

  loadDynamicData() {
    this.getRateTypes();
    this.getSkillsAndServices();
  }

  removeAddedImage(index: number): void {
    this.medias.splice(index, 1);
    this.medias = [...this.medias];
    this.blobMetaRequests.splice(index, 1);

    if (this.medias.length === 0) {
      this.isMediaAddedInFirstStep = false;
      this.showStepperHeader();
    }
    this.cdr.detectChanges();
  }

  patchFormValues(item: RequisitionResponse) {
    this.isPatchingSkills = true;
    this.homeService.getRequisitionDetails(item.id).subscribe((resData) => {
      if (resData) {
        resData.requestedServices.forEach((item) => {
          const patchedSkills = {
            id: item.service.id,
            description: item.service.name,
            value: item.service.name,
            color: 'primary',
            serviceId: item.service.id,
          };
          this.skillsArray = [patchedSkills, ...this.skillsArray];
        });
      }
    });

    this.getCurrencies(this.editableRequisition.currency);

    this.taskValue = item.tasks;
    this.skillsValue = item.services;

    this.addTask(item.tasks);

    this.locationValue = {
      latitude: item.coordinates?.latitude,
      longitude: item.coordinates?.longitude,
      location: item.coordinates?.location || item?.location,
      coords: item?.coordinates?.coords,
    };

    const retrievedStartDate = item.proposedStartDate
      ? new Date(item.proposedStartDate)
      : null;
    const retrievedEndDate = item.proposedEndDate
      ? new Date(item.proposedEndDate)
      : null;

    this.addEditRequisitionForm.patchValue({
      location: item.location,
      proposedStartDate: retrievedStartDate
        ? this.datePipe.transform(retrievedStartDate, 'yyyy-MM-dd')
        : null,
      proposedStartTime: retrievedStartDate
        ? this.datePipe.transform(retrievedStartDate, 'hh:mm a')
        : null,
      proposedEndDate: retrievedEndDate
        ? this.datePipe.transform(retrievedEndDate, 'yyyy-MM-dd')
        : null,
      proposedEndTime: retrievedEndDate
        ? this.datePipe.transform(retrievedEndDate, 'hh:mm a')
        : null,
      isMobile: item.isMobile,
      professionalLocation: item.professionalLocation,
      clientLocation: item.clientLocation,
      isRemote: item.isRemote,
      budget: item.budget,
      description: item.description,
      selectedTask: item.tasks[0],
      selectedSkills: item.services[0],
    });

    this.isPatchingSkills = false;
    this.cdr.detectChanges();
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.addEditRequisitionForm.get(controlName);
    return control
      ? control.invalid && (control.dirty || control.touched)
      : false;
  }

  getLoggedinUserLocation() {
    const username = this.sessionService.getCurrentUsername()!;

    this.subscription.add(
      this.userService.getUserProfileByUsername(username).subscribe({
        next: (resData: UserProfileResponse) => {
          this.locationValue = {
            latitude: resData.coordinates.longitude,
            longitude: resData.coordinates.latitude,
            location: resData.coordinates.location,
            coords: resData.coordinates.coords,
          };

          this.addEditRequisitionForm.patchValue({
            location: this.locationValue.location,
          });
        },
      })
    );
    this.cdr.detectChanges();
  }

  handleSelectedCurrency(cur: string) {
    this.selectedCurrency = cur;
  }

  handleSelectedType(type: SelectOption) {
    this.selectedPayType = type.value;
  }

  handleDateValidation(isValid: boolean) {
    this.isDateValid = isValid;
  }

  handleInputValidation(isValid: boolean) {
    this.schInputValid = isValid;
  }

  getRateTypes() {
    this.lookupService.getPayRateTypes('').subscribe({
      next: (payRateTypes) => {
        const rateTypes = payRateTypes.map((payRateType) => {
          return {
            label: this.titleCasePipe.transform(payRateType.name),
            value: payRateType.description,
          };
        });

        if (this.isEditing) {
          const matchingRate = rateTypes.find(
            (type) => type.label === this.editableRequisition.payRateType
          );

          this.addEditRequisitionForm.patchValue({
            rateTypeId: matchingRate,
          });
        } else {
          const projectRate = rateTypes.find(
            (type) => type.label === 'Project'
          );

          if (projectRate) {
            this.addEditRequisitionForm.patchValue({
              rateTypeId: projectRate,
            });
          }
        }

        this.rateTypes = [...this.rateTypes, ...rateTypes];
        this.cdr.detectChanges();
      },
      error: () => {},
    });
  }

  getCurrencies(keyword?: string, pageSize?: number): void {
    const size = pageSize || this.currencyListSize;

    const payloadType = keyword
      ? this.lookupService.getCurrencies(keyword)
      : this.lookupService.getCurrencies(keyword, 0, size, undefined);
    payloadType.subscribe({
      next: (curr) => {
        this.currencies = curr;

        // if (this.isEditing || this.initialLoad) {
        if (this.addEditRequisitionForm.get('currency')!.value) return;

        this.addEditRequisitionForm.patchValue({
          currency: this.currencies[0],
        });
        // }

        this.cdr.detectChanges();
      },
    });
  }

  handlePagination(): void {
    // Increment the page size by 20
    this.currencyListSize += 20;
    this.getCurrencies('', this.currencyListSize);
  }

  onSelectValueChange(event: any) {
    const value = event.target.value;
    this.currencySearchTerms$.next(value);
  }

  getSkillsAndServices() {
    this.isFetchingSkills = true;
    // this.lookupService.skills('', 0, 100).subscribe({
    //   next: (skills) => {
    //     this.skillsSuggestions = skills;
    //   },
    //   error: (error) => {
    //     // console.error(error);
    //   },
    // });

    this.lookupService.services('', 0, 100).subscribe({
      next: (services) => {
        this.servicesSuggestions = services;

        const suggestions = this.servicesSuggestions.map((suggestion) => {
          return {
            name: suggestion.name,
            value: suggestion.description,
            id: suggestion.id,
          };
        });

        this.skillsToolsTechSuggestions = [
          ...this.skillsToolsTechSuggestions,
          ...suggestions,
        ];

        this.cdr.detectChanges();
        this.isFetchingSkills = false;

        // if (this.skillsSuggestions.length > 0) {
        //   const suggestions = this.mergeSuggestions(
        //     this.skillsSuggestions,
        //     this.servicesSuggestions
        //   );
        //   this.skillsToolsTechSuggestions = [
        //     ...this.skillsToolsTechSuggestions,
        //     ...suggestions,
        //   ];

        // }
      },
      error: () => {},
    });
  }

  mergeSuggestions(
    skillsSuggestions: any[],
    servicesSuggestions: any[]
  ): any[] {
    this.mergedSuggestions = [...skillsSuggestions, ...servicesSuggestions];
    return this.mergedSuggestions.map((suggestion) => {
      return {
        name: suggestion.name,
        value: suggestion.description,
        id: suggestion.id,
      };
    });
  }

  handleSelectionChange(event: any, instance: string): void {
    if (event && !this.isPatchingSkills) {
      event.forEach((e: any) => {
        if (instance === 'skill') {
          this.addSkill(e.value);
        } else if (instance === 'task') {
          this.addTask(e.value);
        }
      });
    }
  }

  onInputValueChange(event: string, instance: string): void {
    if (instance === 'skill') {
      if (
        this.skillsArray.length > this.acceptedSkillsArrayLength &&
        event !== ''
      ) {
        this.skillArrayError = `Skills cannot be more than ${this.acceptedSkillsArrayLength}`;
        return;
      }

      const val = event;
      if (val) {
        this.skillsToolsTechSuggestions =
          this.skillsToolsTechSuggestions.filter((suggestion) => {
            return suggestion.name.toLowerCase().includes(val.toLowerCase());
          });
      } else {
        this.skillsToolsTechSuggestions = this.mergeSuggestions(
          this.skillsSuggestions,
          this.servicesSuggestions
        );
      }
    } else if (instance === 'task') {
      if (
        this.taskArray.length > this.acceptedTaskArrayLength &&
        event !== ''
      ) {
        this.taskArrayError = `Tasks cannot be more than ${this.acceptedTaskArrayLength}`;
        return;
      }

      // this.taskValue = event;
    }
  }

  listenToSelectionLimit(event: boolean, instance: string) {
    if (event && instance === 'task') {
      this.taskArrayError = `Tasks cannot be more than ${this.acceptedTaskArrayLength}`;
    } else if (event && instance === 'skill') {
      this.skillArrayError = `Skills cannot be more than ${this.acceptedSkillsArrayLength}`;
    } else if (!event && instance === 'task') {
      this.taskArrayError = '';
    } else if (!event && instance === 'skill') {
      this.skillArrayError = '';
    }
  }

  onChipsChange(chips: any[], instance: string): void {
    const chipsDescriptions = new Set(chips.map((chip) => chip.value));
    if (instance === 'skill') {
      this.skillsArray = this.skillsArray.filter((skill) =>
        chipsDescriptions.has(skill.description)
      );

      if (this.skillsArray.length < this.acceptedSkillsArrayLength) {
        this.skillArrayError = '';
      }
    } else if (instance === 'task') {
      this.taskArray = this.taskArray.filter((task) =>
        chipsDescriptions.has(task.description)
      );

      if (this.taskArray.length < this.acceptedTaskArrayLength) {
        this.taskArrayError = '';
      }
    }
  }

  addSkill(skill: string | string[]) {
    this.addSkillSubject.next(skill);
  }

  processAddSkill(skill: string | string[]) {
    if (Array.isArray(skill)) {
      // If skill is an array, process each skill in the array
      skill.forEach((s) => this.addSingleSkill(s));
    } else {
      // If skill is a single string, process it directly
      this.addSingleSkill(skill);
    }
  }

  private addSingleSkill(skill: string) {
    skill = skill.trim();

    if (this.skillsArray.length > this.acceptedSkillsArrayLength) {
      return;
    }

    // Check if the skill already exists in skillsArray
    const existingSkillInArray = this.skillsArray.find(
      (existingSkill) => existingSkill.value === skill
    );
    if (existingSkillInArray) {
      this.skillArrayError = 'Skill already exists.';
      return;
    }

    if (skill) {
      this.addEditRequisitionForm.patchValue({
        selectedSkills: skill,
      });

      const existingSkill = this.servicesSuggestions.find(
        (suggestion) => suggestion.name === skill
      );

      if (existingSkill) {
        const newSkill = {
          id: existingSkill.id,
          description: skill,
          value: skill,
          color: 'primary',
          serviceId: existingSkill.id,
        };

        this.skillsArray = [newSkill, ...this.skillsArray];
      } else {
        const payload: ServiceRequest = {
          name: skill,
          description: skill,
          jobTitle: skill,
        };

        this.lookupService.addService(payload).subscribe({
          next: (resData) => {
            const addedSkill = {
              id: resData.id,
              description: resData.description,
              value: resData.description,
              color: 'primary',
              serviceId: resData.id,
            };

            this.skillsArray = [addedSkill, ...this.skillsArray];
            this.cdr.detectChanges();
          },
        });
      }

      this.skillsValue = ''; // Clear the input field
      this.skillArrayError = '';
      this.cdr.detectChanges();
    }
  }

  addTask(task: string | string[]) {
    if (Array.isArray(task)) {
      // If task is an array, process each task in the array
      task.forEach((t) => this.addSingleTask(t));
    } else {
      // If task is a single string, process it directly
      this.addSingleTask(task);
    }
  }

  private addSingleTask(task: string) {
    task = task.trim();

    if (task) {
      if (this.taskArray.length > this.acceptedTaskArrayLength) {
        return;
      }

      this.addEditRequisitionForm.patchValue({
        selectedTask: task,
      });

      // Check if the task already exists in taskArray
      const existingTaskInArray = this.taskArray.find(
        (existingTask) => existingTask.description === task
      );

      if (existingTaskInArray) {
        // this.taskArrayError = 'Task already exists.';
        return;
      }

      const newTask = {
        id: this.taskArray.length,
        description: task,
        value: task,
        color: 'primary',
      };

      this.taskArray = [newTask, ...this.taskArray];
      // this.taskValue = ''; // Clear the input field
      this.taskArrayError = '';
      this.cdr.detectChanges();
    }
  }

  showStepperHeader() {
    if (!this.checkboxValue || this.isMediaAddedInFirstStep) {
      this.hasMultiSteps = true;
    } else {
      this.hasMultiSteps = false;
    }
    this.cdr.detectChanges();
  }

  onItemClick(media: Media) {
    this.modalService.open(GalleryCarouselComponent, {
      data: { selectedMedia: media, mediaItems: this.medias },
      modalClass: 'modal-dialog-centered modal-lg',
    });
  }

  retrieveSelectedLocation(event: Geolocation) {
    this.addEditRequisitionForm.patchValue({
      location: event.coordinates.location,
    });

    this.retrieveCountryCode(event.address.countryCode);

    if (
      event.coordinates.latitude !== this.locationValue.latitude ||
      event.coordinates.longitude !== this.locationValue.longitude
    ) {
      this.locationValue = event.coordinates;
    }
  }

  retrieveCountryCode(countryCode?: string) {
    if (countryCode && !this.isEditing) {
      // this.initialLoad = true;
      // if currency has value and location is changed, allow user update currency manually
      if (this.addEditRequisitionForm.get('currency')!.value) return;
      this.getCurrencyByParams(countryCode);
    }
  }

  getCurrencyByParams(countryCode: string) {
    this.lookupService.getCurrencyByParams(countryCode).subscribe({
      next: (curr) => {
        this.addEditRequisitionForm.patchValue({
          currency: curr,
        });

        this.cdr.detectChanges();
      },
    });
  }

  getProfessionalDetailsById(id: string) {
    this.subscription.add(
      this.userService.getUserProfileById(id).subscribe({
        next: (resData) => {
          this.hiredPro = resData;
          this.cdr.detectChanges();
        },
        error: () => {},
      })
    );
  }

  retrieveSelectedProfessionals(item: ProfessionalSummary[]) {
    //Add IDs
    item.forEach((inv) => {
      const index = this.requestedProfessionalIds.indexOf(inv.user?.id);
      if (index > -1) return;
      this.requestedProfessionalIds.push(inv.user?.id);
    });

    // Remove IDs
    this.requestedProfessionalIds = this.requestedProfessionalIds.filter((id) =>
      item.some((inv) => inv.user?.id === id)
    );
  }

  closeProjectModal() {
    this.modalService.close();
  }

  reqEdited = false;

  submit() {
    if (this.addEditRequisitionForm.invalid) {
      this.addEditRequisitionForm.markAllAsTouched();
      return;
    } else if (this.taskArray.length < 1) {
      this.addEditRequisitionForm.get('selectedTask')?.markAsTouched();
      this.taskArrayError = 'You must add at least one task';
      return;
    } else if (this.skillsArray.length < 1) {
      this.addEditRequisitionForm.get('selectedSkills')?.markAsTouched();
      this.skillArrayError = 'You must add at least one skill';
      return;
    } else if (
      this.skillsArray.length > this.acceptedSkillsArrayLength ||
      this.skillArrayError
    ) {
      this.skillArrayError = `Skills cannot be more than ${this.acceptedSkillsArrayLength}`;
      return;
    } else if (
      this.taskArray.length > this.acceptedTaskArrayLength ||
      this.taskArrayError
    ) {
      this.taskArrayError = `Tasks cannot be more than ${this.acceptedTaskArrayLength}`;
      return;
    } else if (
      !this.checkboxValue &&
      this.requestedProfessionalIds.length === 0
    ) {
      this.noInvitedProError = 'You have not invited a professional';
      return;
    } else if (this.noteDescription.length >= this.noteMaxlength) {
      return;
    } else {
      if (this.isSubmitting) return;

      if (this.hiredPro || !this.checkboxValue) {
        this.addEditRequisitionForm.patchValue({
          privateBidding: true,
        });
      }

      const startDateTime = this.utilityService.convertDateTimeToUTC(
        this.addEditRequisitionForm.value.proposedStartDate,
        this.addEditRequisitionForm.value.proposedStartTime
      );

      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      if (startDateTime! < currentDate) {
        this.addEditRequisitionForm.get('proposedStartDate')?.markAsTouched();
        this.dateError = 'Date can not be in the past';
        return;
      }

      const endDateTime = this.utilityService.convertDateTimeToUTC(
        this.addEditRequisitionForm.value.proposedEndDate,
        this.addEditRequisitionForm.value.proposedEndTime
      );

      if (
        this.datePipe.transform(endDateTime, 'yyyy-MM-dd')! <
        this.datePipe.transform(startDateTime, 'yyyy-MM-dd')!
      ) {
        this.endDateError = 'End date must be greater than the start date';
        return;
      }

      const requestedServices: RequestedServiceRequest[] = [];
      const requestedTasks: RequestedTaskRequest[] = [];
      const invitedProfessionals: InvitedProfessionalRequest[] = [];

      this.skillsArray.forEach((skill) => {
        const requestedService: RequestedServiceRequest = {
          serviceId: skill.serviceId,
          serviceName: skill.description,
        };

        requestedServices.push(requestedService);
      });

      this.taskArray.forEach((task) => {
        const requestedTask: RequestedTaskRequest = {
          description: task.description,
        };

        requestedTasks.push(requestedTask);
      });

      this.requestedProfessionalIds.forEach((profId) => {
        const professional: InvitedProfessionalRequest = {
          professionalId: profId,
        };

        invitedProfessionals.push(professional);
      });

      if (this.hiredPro) {
        invitedProfessionals.push({
          professionalId: this.hiredPro.id,
        });
      }

      const payload: RequisitionRequest = {
        description: this.addEditRequisitionForm.value.description,
        proposedStartDate: startDateTime,
        proposedEndDate: endDateTime,
        expirationDate: endDateTime,
        isRemote: this.addEditRequisitionForm.value.isRemote,
        professionalLocation:
          this.addEditRequisitionForm.value.professionalLocation,
        clientLocation: this.addEditRequisitionForm.value.clientLocation,
        isMobile: this.addEditRequisitionForm.value.isMobile,
        privateBidding: this.addEditRequisitionForm.value.privateBidding,
        currency: this.addEditRequisitionForm.value.currency?.currencyCode,
        budget: this.addEditRequisitionForm.value.budget,
        rateTypeId: this.addEditRequisitionForm.value.rateTypeId?.id,
        coordinates: this.locationValue,
        blobMetaRequests: this.blobMetaRequests,
        invitedProfessionals: invitedProfessionals,
        requestedServices: requestedServices,
        requestedTasks: requestedTasks,
      };

      this.isSubmitting = true;

      const editPayload: UpdateRequisitionRequest = {
        ...payload,
        requisitionId: this.editableRequisition?.id,
      };
      const reqRequest = !this.editingReq
        ? this.requisitionService.create(payload)
        : this.requisitionService.update(editPayload);
      reqRequest.subscribe({
        next: (resData) => {
          if (resData) {
            this.isProjectCreated = true;
            this.addEditRequisitionForm.reset();
            this.skillsArray = [];
            this.taskArray = [];
            this.toastService.success('Project created successfully.');
            this.editingReq && (this.reqEdited = true);
            if (invitedProfessionals.length === 0 || this.hiredPro) {
              this.closeProjectModal();
            }
          }
        },
        error: () => {
          this.toastService.error('Unable to create new job.');
        },
        complete: () => {
          this.isSubmitting = false;
        },
      });
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
