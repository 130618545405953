/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { environment } from '../../../environments/environment';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  Chip,
  ChipConfig,
  ChipOverride,
  ModalService,
  ToastService,
  UxNgModule,
} from '@seech/ux-ng';
import { CharacterLimitDirective, ControlsNgModule } from '@seech/controls-ng';
import { Geolocation } from '@seech/core-ng'
import { ComponentsModule } from '../../modules/general/components/components.module';
import {
  LookupService,
  ProfessionalServiceService,
  ProfessionalServiceRequest,
  ProfessionalSkillRequest,
  Skill,
  ServiceRequest,
  FILE_TYPES,
} from '@profindar/shared-ng';
import { SessionService } from '../../services/session.service';
import { Guid } from 'typescript-guid';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { CommonModule, TitleCasePipe, UpperCasePipe } from '@angular/common';
import { Router } from '@angular/router';
import { IconsNgModule } from '@seech/icons-ng';

@Component({
  selector: 'app-add-edit-pro-service',
  templateUrl: './add-edit-pro-service.component.html',
  styleUrls: ['./add-edit-pro-service.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ControlsNgModule,
    UxNgModule,
    ReactiveFormsModule,
    ComponentsModule,
    IconsNgModule,
    CharacterLimitDirective
  ],
  providers: [TitleCasePipe, UpperCasePipe],
})
export class AddEditProServiceComponent implements OnInit, OnDestroy {
  @Input() editData = null as any;
  cdnBaseUrl: string = environment.cdnBaseUrl;
  sideImage: string = `${this.cdnBaseUrl}images/becomeapro.jpg`;
  skillsToolsTechValue: string | any = '';
  servicesValue: string = '';
  skillsToolsTechArray: Chip[] = [];
  submitBtnText: string = 'Become a Pro';
  isSubmitting: boolean = false;
  formInvalid: boolean = false;
  isFetchingSkills: boolean = false;
  isFetchingServices: boolean = true;
  addProServiceResponse: any = {};
  formSubmitted: boolean = false;
  apiServiceId: string = '';
  apiSkillId: string = '';
  isCurrencySelected: boolean = true;
  isRateTypeSelected: boolean = true;
  isSkillsEntered: boolean = true;
  payRateTypes: any[] = [];
  skillsSuggestions!: any[];
  servicesSuggestions: any[] = [];
  skillsToolsTechSuggestions: any[] = [];
  tempImages: string[] = [];
  currencyList: any[] = [];
  currencyListSize: number = 50;
  fileTypes: any[] = FILE_TYPES;
  responseText: string = 'Success.';
  maxLength = 1000; // Set the maximum length for the textarea
  skillMaxLength = 100; // Set the maximum length for the textarea
  currentCharacterCount = 0;

  private currencySearchTerms$ = new Subject<string>();
  private currencySubscription$!: Subscription;
  private initialSkillsSuggestions: any[] = [];

  public addNewServiceForm!: FormGroup;

  overrides: ChipOverride = {
    exclude: ['secondary'],
    replacement: 'info',
  };

  config: ChipConfig = {
    override: this.overrides,
  };

  payload: ProfessionalServiceRequest = {
    id: Guid.createEmpty().toString(),
    serviceId: '',
    description: '',
    isRemote: false,
    professionalLocation: false,
    clientLocation: false,

    currency: '',
    rate: 0,
    rateTypeId: '',
    coordinates: {
      latitude: 0,
      longitude: 0,
      location: '',
      coords: '',
    },
    professionalSkills: [],
  };

  user: any = {
    isProfessional: false,
  };

  constructor(
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private lookupService: LookupService,
    private cdr: ChangeDetectorRef,
    private sessionService: SessionService,
    private proService: ProfessionalServiceService,
    private toastService: ToastService,
    public titleCasePipe: TitleCasePipe,
    public upperCasePipe: UpperCasePipe,
    private router: Router
  ) {
    const user = this.sessionService.getCurrentUser();
    this.user.isProfessional = user?.isProfessional;
    this.setInitialButtonText();
  }

  initializeForm(): void {
    this.addNewServiceForm = this.formBuilder.group({
      category: [''],
      serviceId: ['', Validators.required],
      description: ['', Validators.required],
      payRate: [''],
      isRemote: [false],
      professionalLocation: [false],
      clientLocation: [false],
      isMobile: [false],
      image: [''],
      currency: [''],
      rateType: [''],
      location: ['', Validators.required],
    });
  }

  setInitialButtonText(): void {
    this.submitBtnText = this.user.isProfessional
      ? 'Add New Service'
      : 'Become a Pro';
  }

  setApiCallButtonText() {
    this.submitBtnText = this.user.isProfessional
      ? 'Adding new service ...'
      : 'Becoming a pro ...';
  }

  resetButtonTextAfterApiCall() {
    this.setInitialButtonText();
  }

  loadDynamicData(): void {
    this.loadPayRateTypes();
    !this.editData && this.loadCurrencies();
  }

  loadPayRateTypes(): void {
    // if (!this.editData) {
    this.lookupService.getPayRateTypes('').subscribe({
      next: (payRateTypes) => {
        this.payRateTypes = payRateTypes.map((p) => ({
          label: this.titleCasePipe.transform(p.name),
          value: p.description,
          id: p.id,
        }));
        this.cdr.detectChanges();
      },
    });
    // } else {
    //   this.defaultRateTypeValue = {
    //     label: this.editData?.payrateTypeResponse?.name,
    //     value: this.editData?.payrateTypeResponse?.description,
    //     id: this.editData?.payrateTypeResponse?.id
    //   }

    //   this.payRateTypes = this.defaultRateTypeValue
    //   // console.log('sent')
    //   this.cdr.detectChanges();

    // }
  }

  loadServiceSuggestions(): void {
    this.lookupService.services('', 0, 100).subscribe({
      next: (services) => {
        this.servicesSuggestions = services;
        this.isFetchingServices = false;
        this.preservedServiceSuggestions = [...services];
        this.cdr.detectChanges();
      },
    });
  }
  loadCurrenciesForEditInit = false;
  loadCurrencies(keyword?: string, pageNumber?: number): void {
    let payloafType = this.lookupService.getCurrencies(
      keyword,
      pageNumber || 0,
      this.currencyListSize,
      undefined
    );
    payloafType.subscribe({
      next: (currencies) => {
        const currencyList = currencies;
        this.loadCurrenciesForEditInit && (this.defaultCurrencyValue = currencies[0]);
        this.loadCurrenciesForEditInit = false;
        this.currencyList =
          keyword === undefined
            ? [...this.currencyList, ...currencyList]
            : currencyList;
        this.cdr.detectChanges();
      },
    });
  }

  onCurrencySelected(event: any) {
    this.isCurrencySelected = true;
    if (event?.currencyName && event?.currencyName !== '') {
      this.defaultCurrencyValue = event;
      this.addNewServiceForm.patchValue({
        currency: event,
      });
      this.setValidators('payRate', [Validators.required]);
      this.setValidators('rateType', [Validators.required]);
    } else {
      this.setValidators('payRate', []);
      this.setValidators('rateType', []);
    }
  }

  onPayRateChange(event: any) {
    const value = event.target.value;
    if (value && value !== '') {
      this.setValidators('currency', [Validators.required]);
      this.setValidators('rateType', [Validators.required]);
    } else {
      this.setValidators('currency', []);
      this.setValidators('rateType', []);
    }
  }

  onRateTypeSelected(event: any) {
    if (event && event !== '') {
      this.setValidators('currency', [Validators.required]);
      this.setValidators('payRate', [Validators.required]);
      this.payload.rateTypeId = event.id;
    } else {
      this.setValidators('currency', []);
      this.setValidators('payRate', []);
    }
  }

  setValidators(controlName: string, validators: any[]) {
    this.addNewServiceForm.get(controlName)?.setValidators(validators);
    this.addNewServiceForm.get(controlName)?.updateValueAndValidity();
  }

  serviceIconClicked: boolean = false;

  addService() {
    const payload: ServiceRequest = {
      name: this.servicesValue,
      description: this.servicesValue,
      jobTitle: this.servicesValue,
    };
    if (this.apiServiceId === '' && payload?.name) {
      this.lookupService.addService(payload).subscribe({
        next: (response) => {
          this.payload.serviceId = response.id;
          this.apiServiceId = response.id;
          this.onSubmit();
          this.cdr.detectChanges();
        },
        error: (error) => { },
      });
    }
  }

  isAddingCustomSkill: boolean = false;
  skillIconClicked: boolean = false;
  addSkill() {
    if (this.apiSkillId === '' && this.skillsToolsTechArray.length > 0) {
      this.isAddingCustomSkill = true;
      this.isSkillsEntered = true;
      const payload: Skill = {
        professionalSkills: [],
        id: '',
        name: this.skillsToolsTechArray[0].value,
        description: this.skillsToolsTechArray[0].description,
        serviceId: this.apiServiceId || '',
      };

      this.lookupService.addSkill(payload).subscribe({
        next: (response) => {
          const proPayloadSkill: ProfessionalSkillRequest = {
            skillId: response.id,
            skillName: response.name,
          };
          this.payload.professionalSkills = [
            ...(this.payload.professionalSkills || []),
            proPayloadSkill,
          ];
          this.isAddingCustomSkill = false;
          this.cdr.detectChanges();
          this.preparePayload();
        },
        error: (error) => {
          this.isAddingCustomSkill = false;
          this.toastService.error('Unable to add skill.');
        },
      });
    }
  }

  handleFailedValidation() {
    this.formInvalid = true;
    this.isSubmitting = false;
    this.resetButtonTextAfterApiCall();
    this.toastService.error('Form not valid.');
  }

  isFormCleared(): boolean {
    if (
      (this.isSubmitting === false && this.addNewServiceForm.valid && !this.isSkillsToolsTechArrayEmpty()) ||
      (this.servicesValue !== '' && !this.isSkillsToolsTechArrayEmpty())
    ) {
      return true
    } else {
      this.handleFailedValidation();
      this.isSkillsToolsTechArrayEmpty()
        ? (this.isSkillsEntered = false)
        : (this.isSkillsEntered = true);
      return false
    }
  }

  onSubmit(): void {
    if (this.editData) {
      this.isFormCleared() && this.editProService()
    } else {
      this.isFormCleared() && (
        this.isSubmitting = true,
        this.setApiCallButtonText(),
        this.formInvalid = false,
        this.handleAddCustomServiceOnSubmit()
      )
    }
  }
  isEdited: boolean = false;
  editProService() {
    this.payload = {
      ...this.payload,
      description: this.getFormValues().description,
      isRemote: this.getFormValues().isRemote,
      professionalLocation: this.getFormValues().professionalLocation,
      clientLocation: this.getFormValues().clientLocation,
      currency: this.getFormValues().currency?.currencyCode,
      rate: this.getFormValues().payRate,
      // rateTypeId: this.getFormValues().rateType?.id || this.defaultRateTypeValue?.id,
    };
    this.submitBtnText = 'Updating Service ...';
    this.proService.patchProfessionalService(this.payload).subscribe({
      next: (response: any) => {
        this.isSubmitting = false;
        this.formSubmitted = true;
        this.resetButtonTextAfterApiCall();
        this.addNewServiceForm.reset();
        this.responseText = 'Service updated successfully';
        this.isEdited = true;
        this.toastService.success('Service updated successfully');
        this.setInitialButtonText();
        this.cdr.detectChanges();
      },
      error: (error) => {
        this.toastService.error(
          "Unable to update service."
        );
        this.isSubmitting = false;
        this.resetButtonTextAfterApiCall();
      },
    })
  }

  private handleAddCustomServiceOnSubmit() {
    if (this.apiServiceId !== '') {
      this.addNewServiceForm.valid &&
        !this.isSkillsToolsTechArrayEmpty()
        ? this.preparePayload()
        : ((this.formInvalid = true),
          (this.isSubmitting = false),
          this.resetButtonTextAfterApiCall(),
          this.isSkillsToolsTechArrayEmpty()
            ? (this.isSkillsEntered = false)
            : (this.isSkillsEntered = true));
    } else {
      this.addService();
    }
  }

  getFormValues() {
    const {
      description,
      isRemote,
      professionalLocation,
      clientLocation,
      isMobile,
      currency,
      payRate,
      rateType,
    } = this.addNewServiceForm.value;
    return {
      description,
      isRemote,
      professionalLocation,
      clientLocation,
      isMobile,
      currency,
      payRate,
      rateType,
    };
  }

  private preparePayload(): void {
    this.payload = {
      ...this.payload,
      description: this.getFormValues().description,
      isRemote: this.getFormValues().isRemote,
      professionalLocation: this.getFormValues().professionalLocation,
      clientLocation: this.getFormValues().clientLocation,
      currency: this.getFormValues().currency?.currencyCode,
      rate: this.getFormValues().payRate,
      // rateTypeId: this.getFormValues().rateType?.id
    };

    this.addNewServiceForm.valid &&
      this.proService.addProfessionalService(this.payload).subscribe({
        next: (response: any) => {
          this.isSubmitting = false;
          this.formSubmitted = true;
          this.resetButtonTextAfterApiCall();
          this.addNewServiceForm.reset();
          this.cdr.detectChanges();
        },
        error: (error) => {
          this.toastService.error(
            "Unable to add service."
          );
          this.isSubmitting = false;
          this.resetButtonTextAfterApiCall();
        },
      });
  }

  onClosed(): void {
    this.modalService.close();
  }

  onFileSelected(event: Event): void {
    const element = event.currentTarget as HTMLInputElement;
    const file: File | null = element.files ? element.files[0] : null;

    if (file) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        const target = e.target as FileReader;
        this.tempImages.push(target.result as string);
        this.addNewServiceForm.patchValue({
          image: file,
        });
      };
      reader.readAsDataURL(file);
    }
  }

  addSkillsToolsTech(
    skillsToolsTech: string,
    proPayloadSkill?: ProfessionalSkillRequest
  ) {
    const value = skillsToolsTech.trim();
    this.skillsToolsTechValue = '';
    // const exists = this.skillsToolsTechArray.some(
    //   (skill) => skill.value === value
    // );
    // if (!exists) {
    // if (skillsToolsTech) {
    // const newskillsToolsTech: Chip = {
    //   description: value,
    //   value: value,
    //   id: this.skillsToolsTechArray.length,
    //   color: 'primary',
    // };
    // this.skillsToolsTechArray = [
    //   ...this.skillsToolsTechArray,
    //   newskillsToolsTech,
    // ];

    if (proPayloadSkill) {
      this.payload.professionalSkills = [
        ...(this.payload.professionalSkills || []),
        proPayloadSkill,
      ];
    } else {
      this.payload.professionalSkills = [
        ...(this.payload.professionalSkills || []),
        {
          skillId: Guid.createEmpty().toString(),
          skillName: value,
        },
      ];
    }
    // }
    // }
    this.isSkillsEntered = true;
  }
  showAddSkillsIcon: boolean = false;


  onInputValueChange(event: Event | string): void {

    const val = event as string;
    // this.skillsToolsTechValue = event as string;

    if (val) {
      const filteredSuggestions = this.initialSkillsSuggestions.filter((suggestion) =>
        suggestion.name.toLowerCase().includes(val.toLowerCase())
      );
      if (filteredSuggestions.length) {
        this.skillsSuggestions = filteredSuggestions;
      } else {
        this.skillsSuggestions = [...this.initialSkillsSuggestions]

      }
      filteredSuggestions.length === 0 ? this.showAddSkillsIcon = true : this.showAddSkillsIcon = false;
    } else {
      this.skillsSuggestions = [...this.initialSkillsSuggestions];
    }


    this.cdr.detectChanges();
  }



  preservedServiceSuggestions: any[] = [];
  onServiceInputValueChange(event: string): void {
    this.servicesValue = event || '';
    if (this.servicesValue.trim() !== '') {
      this.lookupService.services(this.servicesValue, 0, 50).subscribe({
        next: (services) => {
          this.preservedServiceSuggestions = [...services];
          this.cdr.detectChanges();
        },
        error: (error) => { },
      });
    } else {
      this.servicesSuggestions = [...this.preservedServiceSuggestions];
      this.cdr.detectChanges();
    }
  }

  updatePayloadwithSkill(event: any) {
    // console.log('events', event)
    event.map((skill: any) => {
      const skillExists = this.payload.professionalSkills?.some(
        (existingSkill: any) => existingSkill.skillId === skill.id || existingSkill.skillName === skill.name
      );

      if (!skillExists) {
        this.payload.professionalSkills = [
          ...(this.payload.professionalSkills || []),
          {
            skillId: !skill.id ? Guid.createEmpty().toString() : skill.id,
            skillName: skill.name,
          },
        ];
      }

      // Set the apiSkillId if necessary
      this.apiSkillId = skill.id;
      this.isSkillsEntered = true;
      // console.log('payload',this.payload)
    });
  }

  handleSelectionChange(event: any): void {
    if (event.length > 0) {
      this.skillsToolsTechArray = event
      this.updatePayloadwithSkill(event)
      this.cdr.detectChanges();
    } else {
      this.skillsToolsTechValue = this.editData.skillResponse.map((skill: any) => skill.name);
      this.skillsToolsTechArray = [...this.skillsToolsTechArray, ...this.editData.skillResponse];
      this.updatePayloadwithSkill(this.skillsToolsTechArray)
      // console.log('testing', this.skillsToolsTechValue, this.skillsToolsTechArray)
      this.cdr.detectChanges();
    }
    this.isSkillNameLimitErr = false;
  }

  handleServiceSelectionChange(event: any): void {
    this.addNewServiceForm.patchValue({
      serviceId: event.name,
    });
    this.servicesValue = '';
    if (event) {
      this.payload.serviceId = event.id;
      this.apiServiceId = event.id;
      this.cdr.detectChanges();
      this.isFetchingSkills = true;
      this.lookupService.getSkillsByServiceId(event.id, '', 0, 10).subscribe({
        next: (skills) => {
          this.servicesValue = event.name || '';
          this.skillsSuggestions = [...skills];
          this.initialSkillsSuggestions = [...this.skillsSuggestions]
          this.isFetchingSkills = false;
          this.cdr.detectChanges();
        },
        error: (error) => { },
      });
      this.servicesValue = event?.name || '';
      this.cdr.detectChanges();
    }
  }

  onSelectValueChange(event: any) {
    const { value, name } = event?.target;
    this.currencySearchTerms$.next(value);
  }



  formControlValidityCheck(controlName: string): boolean {
    const validity = (this.addNewServiceForm.get(controlName)?.invalid &&
      (this.addNewServiceForm.get(controlName)?.dirty ||
        this.addNewServiceForm.get(controlName)?.touched ||
        this.formInvalid)) as boolean;

    return validity;
  }
  currencyPageNumber: number = 0;
  handlePagination(): void {
    this.currencyPageNumber += 1;
    this.loadCurrencies(undefined, this.currencyPageNumber);
  }

  isSkillsToolsTechArrayEmpty(): boolean {
    return this.skillsToolsTechArray.length === 0;
  }

  defaultRateTypeValue: any;
  defaultCurrencyValue: any = {
    countryCode: '',
    currencyCode: '',
    currencyName: '',
  };

  ngOnInit(): void {
    this.initializeForm();
    this.loadDynamicData();
    this.currencySubscription$ = this.currencySearchTerms$
      .pipe(debounceTime(300))
      .subscribe((value) => {
        this.loadCurrencies(value);
      });
    this.addNewServiceForm.reset();
    this.patchFormWithEditData();
  }

  onLocationSelected(location: Geolocation): void {
    if (
      location.coordinates.latitude !== this.payload.coordinates.latitude ||
      location.coordinates.longitude !== this.payload.coordinates.longitude
    ) {
      this.addNewServiceForm.patchValue({
        location: location.coordinates.location,
      });
      this.payload.coordinates = location.coordinates;
    }

  }

  removeAddedImage(index: number): void {
    this.tempImages.splice(index, 1);
    this.addNewServiceForm.patchValue({
      image: '',
    });
  }

  patchFormWithEditData() {
    if (this.editData) {
      this.submitBtnText = 'Update Service';
      const { professionalService, skillResponse, serviceResponse, payrateTypeResponse }: any = this.editData;
      this.loadCurrenciesForEditInit = true;
      this.editData?.professionalService?.currency && this.loadCurrencies(this.editData?.professionalService?.currency || null);
      this.lookupService.getSkillsByServiceId(serviceResponse?.id, '', 0, 10).subscribe({
        next: (skills) => {
          this.servicesValue = serviceResponse?.name || '';
          this.skillsSuggestions = [...skills];
          this.initialSkillsSuggestions = [...this.skillsSuggestions]
          this.isFetchingSkills = false;
          this.skillsToolsTechValue = skillResponse.map((skill: any) => skill.name);
          this.skillsToolsTechArray = skillResponse;
          this.cdr.detectChanges();
        },
        error: (error) => { },
      });
      // this.defaultRateTypeValue = {
      //   label: payrateTypeResponse?.name,
      //   value: payrateTypeResponse?.description,
      //   id: payrateTypeResponse?.id
      // }
      this.defaultRateTypeValue = payrateTypeResponse.description
      this.addNewServiceForm.patchValue({
        serviceId: serviceResponse?.name || '',
        description: professionalService?.description || '',
        isRemote: professionalService?.isRemote || false,
        professionalLocation: professionalService?.professionalLocation || false,
        clientLocation: professionalService?.clientLocation || false,
        isMobile: professionalService?.isMobile || false,
        currency: professionalService?.currency || '',
        payRate: professionalService?.rate || '',
        rateType: this.defaultRateTypeValue || '',
        location: professionalService?.location || '',
      });


      this.apiServiceId = professionalService?.serviceId || '';
      this.apiSkillId = skillResponse?.length > 0 ? skillResponse[0].id : '';
      this.servicesValue = serviceResponse?.name || '';

      // this.skillsToolsTechArray = skillResponse?.map((skill: any) => ({
      //   value: skill.name,
      //   description: skill.description,
      //   id: skill.id,
      //   color: 'primary',
      // })) || [];

      this.payload = {
        ...this.payload,
        serviceId: professionalService?.serviceId || '',
        description: professionalService?.description || '',
        isRemote: professionalService?.isRemote || false,
        professionalLocation: professionalService?.professionalLocation || false,
        clientLocation: professionalService?.clientLocation || false,
        currency: professionalService?.currency || '',
        rate: professionalService?.rate || '',
        rateTypeId: professionalService?.rateTypeId || '',
        coordinates: professionalService?.coordinates,
        id: professionalService?.id || '',
      };

      this.handleSelectionChange([]);
      this.cdr.detectChanges();
    }
  }

  onTextAreaChange(): void {
    // const descriptionControl = this.addNewServiceForm.get('description');
    // if (descriptionControl) {
    //   this.currentCharacterCount = descriptionControl.value.length;
    // }
  }

  onSkillChipsDelete(event: any): void {
    const hasSkills = event.length > 0;

    // Reset common properties
    this.skillsToolsTechArray = [];
    this.payload.professionalSkills = [];
    this.apiServiceId = "";

    // Handle additional logic if skills exist
    if (hasSkills) {
      this.handleSelectionChange(event);
    }
  }
  isSkillNameLimitErr = false;
  onCharacterLimitReached(e: any) {
    this.isSkillNameLimitErr = true;
    this.toastService.error("Maximum character limit of 100 exceeded.")
  }

  ngOnDestroy() {
    this.currencySubscription$.unsubscribe();
  }
}
