import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { Media, MessageExtension } from '@profindar/shared-ng';

@Directive({
  selector: '[appChatImageCollage]',
  standalone: true
})
export class ChatImageCollageDirective {
  private get element(): HTMLElement {
    return this.el.nativeElement as HTMLElement;
  }

  @Input() set appChatImageCollage(message: MessageExtension) {
    this.initialize(message);
  }

  constructor(private el: ElementRef, 
              private renderer: Renderer2) {}

  initialize(message: MessageExtension) {    
    this.element.innerHTML = "";
    const truncatedList = message.media.slice(0, 4);

    this.element.style.display = 'grid';
    this.element.style.gridTemplateColumns = 'repeat(2, 1fr)';
    this.element.style.gridTemplateRows = 'repeat(2, 1fr)';
    this.element.style.columnGap = '6px';
    this.element.style.rowGap = '4px';

    for(let i = 0; i < truncatedList.length; i++){
      const newElement = this.createImg(truncatedList[i]);

      switch(truncatedList.length){
        case 1: {
          newElement.style.gridColumnStart = '1';
          newElement.style.gridColumnEnd = '3';
          newElement.style.gridRowStart = '1';
          newElement.style.gridRowEnd = '3';

          newElement.style.borderRadius = '8px';
          break;
        }
        case 2: {
          newElement.style.gridRowStart = '1';
          newElement.style.gridRowEnd = '3';
          break;
        }
        case 3: {
          if(i === 1){
            newElement.style.gridColumnStart = '2';
            newElement.style.gridColumnEnd = '3';
            newElement.style.gridRowStart = '1';
            newElement.style.gridRowEnd = '3';
          }
          break;
        }
        case 4: {
          if(message.media.length > 4 && i === 3){
            const overlay = this.createOverlay(message.media.length - 4);
            this.renderer.appendChild(newElement, overlay);
          }
        }
      }

      this.renderer.appendChild(this.element, newElement);
    }
    
  }

  private createImg(media: Media): HTMLElement {
    const imgElement = this.renderer.createElement('img') as HTMLImageElement;
  
    imgElement.src = media.src;
    imgElement.style.position = 'relative';
    imgElement.style.objectFit = 'cover';
    imgElement.style.borderRadius = '5.68px';
    imgElement.style.width = '100%'; // Ensures it adjusts to the container width
    imgElement.style.height = '100%'; // Ensures it adjusts to the container height
  
    return imgElement;
  }

  private createOverlay(count: number): HTMLElement{
    const overlay = this.renderer.createElement('div') as HTMLElement;

    overlay.style.fontSize = 'calc(1vw + 1vh)'; //fallback for old browsers
    overlay.style.fontSize = '30cqw';
    overlay.style.fontWeight = '600';
    overlay.style.color = '#ffffff';
    overlay.style.backgroundColor = '#00000080';
    overlay.style.height = '100%';
    overlay.style.width = '100%';
    overlay.style.position = 'absolute';
    overlay.style.display = 'flex';
    overlay.style.alignItems = 'center';
    overlay.style.justifyContent = 'center';
    overlay.style.borderRadius = 'inherit';
    overlay.textContent = `+${count.toString()}`;

    return overlay;
  }

}

