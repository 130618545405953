/* eslint-disable @typescript-eslint/no-inferrable-types */
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { GeneralModalComponent } from '../../general-modal/general-modal.component';
import { FileService } from 'apps/profindar-web/src/app/services/file.service';
import { BlobMetaRequest } from '@profindar/shared-ng';
import { ToastService } from '@seech/ux-ng';
import { environment } from '../../../../../../environments/environment';

type FileSelectionType = 'media' | 'document';

@Component({
  selector: 'app-media-upload-preview',
  templateUrl: './media-upload-preview.component.html',
  styleUrls: ['./media-upload-preview.component.scss'],
})
export class MediaUploadPreviewComponent implements OnChanges {
  @ViewChild('modalContainer') modalContainer!: GeneralModalComponent;
  @Output() sendMessage: EventEmitter<any> = new EventEmitter();
  @Output() selectMore: EventEmitter<any> = new EventEmitter();
  @Output() closePreview: EventEmitter<void> = new EventEmitter();
  @Input() files!: FileList;
  @Input() fileType!: FileSelectionType;
  @Input() MAX_UPLOAD_LIMIT: number = 10;

  cdnBaseUrl = environment.cdnBaseUrl;
  documentList: any[] = [];
  messageBody: string = '';
  mediaList: SafeResourceUrl[] = [];
  selectedMedia: number = 0;
  url: string = ''
  doc!: SafeResourceUrl
  blobMetaRequests: BlobMetaRequest[] = [];
  showSpinner = false;
  filename = ''
  preserveExistingFileType = '';

  svgMap: { [key: string]: string } = {
    pdf: `${this.cdnBaseUrl}images/pdf.svg`,
    docx: `${this.cdnBaseUrl}images/docx.svg`,
    pptx: ` ${this.cdnBaseUrl}images/pptx.svg`,
    xlsx: `${this.cdnBaseUrl}images/xlsx.svg`,
    xls: `${this.cdnBaseUrl}images/xlsx.svg`,
    txt: `${this.cdnBaseUrl}images/default.svg`
  };

  constructor(private sanitizer: DomSanitizer, private cdr: ChangeDetectorRef, 
              private fileService: FileService, public toastrService: ToastService){}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['files']?.currentValue){
      const files =  changes['files']?.currentValue
      this.stageMediaFiles(files)
    }
  }

  stageMediaFiles(files: FileList) {
    
    if(this.mediaList.length < 1)  this.showSpinner = true

    const fileArray = Array.from(files);
    this.fileService
      .stageUpload(fileArray).subscribe({
        next: (responses) => {
          const blobRequestData = responses.map((res) => this.fileService.createBlobMetaRequest(res));
          this.blobMetaRequests = [...this.blobMetaRequests,...blobRequestData];
          this.getResourceUrl(files)
        },
        error:()=>{
          this.showSpinner = false
          this.toastrService.error("Image upload failed");
          this.cdr.detectChanges();
        },
        complete:()=>{
          this.showSpinner = false
          this.cdr.detectChanges();
        }
      });
  }

  getResourceUrl(files?: FileList){
    const additions = Array.from(files ?? this.files).map(file => {
    const url = URL.createObjectURL(file);
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    });
    if (this.fileType === 'media') {
    this.mediaList = [...this.mediaList, ...additions];
    } else if (this.fileType === 'document') {
      this.doc = additions[0];
      this.filename = this.files[0].name
    }
    this.showSpinner = false;
    this.cdr.detectChanges();
  }

  getPreviewImage(file: File){
    const fileName = file.name;
    const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1);
    const url = this.svgMap[fileExtension] || this.svgMap['txt']; // Fallback to "txt" if type is not found
    return `<img src="${url}" alt="${fileExtension}" />`;
  }
  
  removeMedia(index: number){
    if(this.mediaList.length === 1) {
      this.closePreview.emit()
    }
    else{
      this.mediaList.splice(index, 1);
      this.blobMetaRequests.splice(index, 1)
      this.selectedMedia = this.selectedMedia === index && index != 0 ? this.selectedMedia - 1 : this.selectedMedia;
    }
  }

  handleKeyUp(event: any) {
    if (!event.shiftKey) {
      this.sendMessage.emit(this.messageBody);
    }
  }

  sendMessageWithMedia() {
    const message = {
      messageBody: this.messageBody,
      blobMetaRequests: this.blobMetaRequests
    };
    this.sendMessage.emit(message);
  }

  selectFile(){
    let allowMultiple = false;
    if (this.fileType === 'media') { allowMultiple = true  }
    const selectedFileTypeOptions = { fileType: this.fileType, allowMultiple: allowMultiple };
    this.selectMore.emit(selectedFileTypeOptions);
  }
  
}
