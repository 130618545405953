/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-empty */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { BlobMetaRequest, CONSTANT, Message, MessageExtension, MessageRequest, MessageService, ThreadPreview, ThreadResponse, User, UserSummary } from '@profindar/shared-ng';
import { SessionService } from '../../../../services/session.service';
import { Observable, Subject, Subscription, catchError, forkJoin, map, of, switchMap, takeUntil } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { Guid } from 'typescript-guid';
import { RealtimeService } from '../../../../services/realtime.service';
import { ModalService, ToastService } from '@seech/ux-ng';
import { FileUploadComponent, ApplicationBlobType } from '@seech/controls-ng';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { StreamingService } from '@seech/core-ng';
import { GalleryCarouselComponent } from '../gallery-carousel/gallery-carousel.component';
import { SafeHtml } from '@angular/platform-browser';
import { Media } from '@seech/media-ng';

type FileSelectionType = 'media' | 'document';
type Source = 'camera' | 'file'

@Component({
  selector: 'app-general-message',
  templateUrl: './general-message.component.html',
  styleUrls: ['./general-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeneralMessageComponent implements OnInit, OnChanges, OnDestroy {
  cdnBaseUrl = environment.cdnBaseUrl;
  @ViewChild('messageAreaContent', { static: false }) messageAreaContent!: ElementRef;
  @ViewChild('inputBox') inputBoxRef!: ElementRef;
  @ViewChild('fileUpload') fileUpload!: FileUploadComponent;
  @Output() openProfile: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() goBack: EventEmitter<void> = new EventEmitter<void>();
  @Output() newThreadCreated: EventEmitter<Message> = new EventEmitter<Message>();
  @Output() updateThreadPreview: EventEmitter<Message> = new EventEmitter<Message>();
  @Output() particpantClick: EventEmitter<UserSummary> = new EventEmitter();
  @Input() conversation?: ThreadPreview | null;
  @Input() profileOpen: boolean = false;
  @Input() startingNewThread = false;
  @Input() standAlone = false;
  @Input() projectId?: string;
  conversations?: MessageExtension[] ;
  showParticipants = false;
  loadingRecipients = true;
  sub: Subscription = new Subscription();
  userProfile!: User;
  messageBody: string = '';
  styleDeclaration = <CSSStyleDeclaration>{
    fontSize: '1rem'
  }
  selectFileSub?: Subscription;
  selectedFiles?: FileList;
  selectingFileType?: FileSelectionType;
  fileType!: FileSelectionType;
  sourceType!: Source;
  blobMetaRequests: BlobMetaRequest[] = [];
  imageFiletypes: ApplicationBlobType[] = ['media'];
  docFiletypes: ApplicationBlobType[] = ['document', 'web', 'archive'];
  allowMultiple = true;
  messageWithMedia: MessageExtension = <MessageExtension>{}
  MAX_UPLOAD_LIMIT = 10;
  loadedSvgs: { [key: string]: SafeHtml } = {};
  loadingConversations = false;

  svgMap: { [key: string]: string } = {
    pdf: `${this.cdnBaseUrl}images/pdf.svg`,
    docx: `${this.cdnBaseUrl}images/docx.svg`,
    pptx: ` ${this.cdnBaseUrl}images/pptx.svg`,
    xlsx: `${this.cdnBaseUrl}images/xlsx.svg`,
    xls: `${this.cdnBaseUrl}images/xlsx.svg`,
    txt: `${this.cdnBaseUrl}images/default.svg`
  };

  imageMap: { [key: string]: string } = {
    pdf: `${this.cdnBaseUrl}images/placeholders/pdf.jpg`,
    docx: `${this.cdnBaseUrl}images/placeholders/doc.jpg`,
    pptx: ` ${this.cdnBaseUrl}images/placeholders/powerpoint.jpg`,
    xlsx: `${this.cdnBaseUrl}images/placeholders/excel.jpg`,
    xls: `${this.cdnBaseUrl}images/placeholders/excel.jpg`,
    txt: `${this.cdnBaseUrl}images/placeholders/text.jpg`
  };

  constructor(private messageService: MessageService,
    private cdr: ChangeDetectorRef,
    private realtimeService: RealtimeService,
    private toastService: ToastService,
    private modalService: ModalService,
    private breakpointObserver: BreakpointObserver,
    private renderer: Renderer2,
    public toastrService: ToastService,
    private streamingService: StreamingService,
    private sessionService: SessionService) { }

  ngOnInit(): void {
    this.userProfile = this.sessionService.getCurrentUser()!;
    this.messageListener();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const previousValue = changes['conversation']?.previousValue;
    const conversation = changes['conversation']?.currentValue;
    if (previousValue?.message?.threadID != conversation?.message?.threadID) {
      const isPlaceholder = conversation?.message.id === Guid.createEmpty().toString();
      this.conversations = isPlaceholder ? [] : undefined;
      this.loadingRecipients = false;
      this.hideMobileOptions();
      this.messageBody = '';
      if(isPlaceholder) this.getThread();
    }
    else if (conversation === null) {
      this.loadingRecipients = false;
    }
  }

  get displayIsMobile(){
    return this.breakpointObserver.isMatched(Breakpoints.XSmall);
  }

  messageListener() {
    this.realtimeService.startListener(CONSTANT.REALTIME.METHOD.PUSH, CONSTANT.REALTIME.CHANNEL.MESSAGE)
    this.getMessageRealTime();
  }

  processBlobResponse(message: any): void {
    const messageWithBlob = message.blobMeta.map((blobItem: { url: string; blobModifiedUrl: string }) => 
      this.getBlob(blobItem.url).pipe(
        map((blob) => {
          const objectUrl = URL.createObjectURL(blob as Blob);
          return { ...blobItem, blobModifiedUrl: objectUrl };
        })
      )
    );
  
    forkJoin(messageWithBlob).subscribe({
      next: (updatedBlobMeta) => {
        message.blobMeta = updatedBlobMeta; // Update the message with processed URLs
        this.processAndRenderMessage(message);
      }
    });
  }

  getMessageRealTime() {
    this.realtimeService.subject
      .subscribe({
        next: (message: any) => {
          if(message.blobMeta.length > 0){
            this.processBlobResponse(message)
          }
          else{
            this.processAndRenderMessage(message);
          }
         },
      });
  }

  private processAndRenderMessage(message: any): void {
    const mappedMessage: MessageExtension = this.mapToMessageExtension(message);
    
    if (mappedMessage.threadID === this.conversation?.message.threadID) {
      this.conversations = this.conversations
        ? [...this.conversations, mappedMessage]
        : [mappedMessage];
      this.scrollToBottom();
    }
  
    // Emit updates and trigger change detection
    this.updateThreadPreview.emit(message);
    this.cdr.detectChanges();
  }

  get isMobileOptionsOpen(): boolean {
    return this.inputBoxRef.nativeElement.classList.contains('show-options');
  }
  openMobileOptions() {
    if (this.inputBoxRef)
      this.renderer.addClass(this.inputBoxRef.nativeElement, 'show-options');
  }
  hideMobileOptions() {
    if (this.inputBoxRef && this.isMobileOptionsOpen)
      this.renderer.removeClass(this.inputBoxRef.nativeElement, 'show-options');
  }

  get fileTypes(): ApplicationBlobType[] | string[]{
    switch (this.selectingFileType) {
      case 'media':
        return this.imageFiletypes;
      case 'document':
        return this.docFiletypes;
      default:
        return [];
    } 
  }

  get multiple(): number {
    switch (this.selectingFileType) {
      case 'media':
        return this.MAX_UPLOAD_LIMIT;
      case 'document':
        return this.MAX_UPLOAD_LIMIT = 1;
      default:
        return 0;
    } 
  }

  get capture(){
    return this.sourceType === 'camera' ? 'environment' : null;
  }

  selectFile(type: FileSelectionType, allowMultiple: boolean) {
    this.allowMultiple = allowMultiple
    this.selectFileSub?.unsubscribe();
    this.selectingFileType = type;
    this.fileType = type;
    this.cdr.detectChanges();
    this.fileUpload.triggerUpload();

    this.selectFileSub = this.fileUpload.selected.subscribe((event: InputEvent) => {
      this.selectingFileType = undefined;
      const files = (event.target as HTMLInputElement).files;
      if(files){
        this.selectedFiles = files;
      }
    });
  }

  onFileError(error: string){
    this.toastrService.error(error);
  }

  onMediaChatClick(mesageExt: MessageExtension) {
    if (!mesageExt.blobMeta || mesageExt.blobMeta.length === 0) return; 
    const mediaItems = mesageExt.blobMeta.map(x => {
        return <Media>{
            id: Guid.create().toString(),
            src: x.url,
            type: x.blobType
        };
    });

    this.modalService.open(GalleryCarouselComponent, {
        data: { 
            selectedMedia: mediaItems[0], 
            mediaItems: mediaItems 
        },
        modalClass: 'modal-dialog-centered modal-lg'
    });
  }

  sendMessageWithMedia(event: any){
    this.messageBody = event.messageBody;
    this.blobMetaRequests = event.blobMetaRequests as Array<BlobMetaRequest>
    this.sendMessage();
    this.closeMediaPreviewOverlay();
  }

  sendMoreMedia(event: any){
    this.selectFile(event.fileType, event.allowMultiple);
  }

  closeMediaPreviewOverlay(){
    this.selectedFiles = undefined;
    this.fileUpload.clearSelection();
  }
 
  getThread() {
    const page = 0;
    const size = 20;
    this.loadingConversations = true;
    
    this.sub.add(
      this.getMessageThread(page, size)
        .pipe(
          map((res: Message[]) => {
            if (res && res.length > 0) {
              return res
                .sort((a, b) => new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime())
                .map((message) => ({
                  ...message,
                  status: 'sent',
                  media: (message.blobMeta || []).map((blobMeta) => ({
                    src: blobMeta.blobModifiedUrl,
                    type: blobMeta.blobType
                  }))
                } as MessageExtension));
            } else return [];
          })
        )
        .subscribe({
          next: (res: MessageExtension[]) => {
            this.conversations = res;
            this.cdr.markForCheck();
            this.scrollToBottom();
            this.loadingConversations = false;
          },
          complete: () => {
            this.loadingConversations = false;
            this.cdr.detectChanges()
          }
        })
    );
  }
  

  get participantCount(): number {
    const participants = this.conversation?.user;
    return participants?.length ?? 0;
  }

  scrollToBottom() {
    setTimeout(() => {
      if (this.messageAreaContent) {
        const element = this.messageAreaContent.nativeElement as HTMLElement;
        if (element) {
          element.scrollTo({ left: 0, top: element.scrollHeight, behavior: 'smooth' });
        }
      }
    }, 100);
  }

  handleKeyUp(event: any) {
    if (!event.shiftKey && !this.displayIsMobile) {
      this.sendMessage();
    }
  }

  getBlob(url: string) {
    return this.streamingService.stream(url); //Shared-ng-stream-service
  }
  
  mapToMessageExtension(message: Message): MessageExtension {
    return {
      ...message,
      status: "sent", 
      media: message.blobMeta?.map(blobMeta => ({
        src: blobMeta.url,
        type: blobMeta.blobType 
      })) || []
    };
  }

  sendMessage(messageToResend?: Message | MessageExtension) {//
    if (!this.messageBody.trim() && !messageToResend && this.blobMetaRequests.length < 1) return;

    const callBack = (res: Message, placeholderId: string): void => {
      const mappedMessage: MessageExtension = this.mapToMessageExtension(res);
    
      // Process blobMeta and create Object URLs
      if (mappedMessage.blobMeta?.length) {
        const blobMetaObservables = mappedMessage.blobMeta.map((blobItem) =>
          this.getBlob(blobItem.url).pipe(
            map((blob) => {
              const updatedBlobItem = { ...blobItem };
              updatedBlobItem.blobModifiedUrl = URL.createObjectURL(blob as Blob);
              return updatedBlobItem;
            }),
            catchError(() => {
              return of(blobItem); 
            })
          )
        );
    
        forkJoin(blobMetaObservables).subscribe((updatedBlobMeta) => {
          mappedMessage.blobMeta = updatedBlobMeta;
    
          // Update conversations after blobMeta URLs are processed
          this.updateConversations(res, placeholderId, mappedMessage);
        });
      } else {
        // Directly update conversations if no blobMeta exists
        this.updateConversations(res, placeholderId, mappedMessage);
      }
    };
    
    const payload: MessageRequest = {
      id: Guid.createEmpty().toString(),
      threadID: '',
      body: messageToResend ? messageToResend.body : this.messageBody.trim(),
      blobMetaRequests: this.blobMetaRequests
    }

    let placeholderConversation: MessageExtension = messageToResend as MessageExtension;
    if (!messageToResend) {
      placeholderConversation = <MessageExtension>{
        id: Guid.create().toString(),
        threadID: this.conversation?.message.threadID,
        body: this.messageBody,
        createdBy: this.userProfile.id,
        status: 'sending',
        blobMeta: this.blobMetaRequests
      };

      this.conversations = this.conversations ? [...this.conversations, placeholderConversation] : [placeholderConversation];
      this.messageBody = '';
      this.scrollToBottom();
    }
    this.cdr.markForCheck();

    // check if its a new message
    if (this.conversation?.message.threadID === Guid.createEmpty().toString()) {
      const participants = this.conversation?.user!.map(x => x.id);
      if(!participants) return;
      let newThread$: Observable<ThreadResponse> = new Observable();
      if (this.standAlone) {
        newThread$ = this.messageService.createStandaloneThread(this.projectId!, participants);
      }
      else if (this.startingNewThread) {
        newThread$ = this.messageService.createThread(participants);
      }

      newThread$.pipe(
        switchMap((response: ThreadResponse) => {
          payload.threadID = response.threadId;
          return this.messageService.sendMessage(payload);
        }),
      ).subscribe(
        (res: Message) => {
          this.newThreadCreated.emit(res);
          callBack(res, placeholderConversation.id);
        }, () => {
          placeholderConversation.status = 'error';
          this.toastService.error('Unable to send message, hit resend to try again');
        }, () => {
          this.cdr.markForCheck();
        });
    }
    else {
      payload.threadID = this.conversation!.message.threadID;
      this.messageService.sendMessage(payload).subscribe(
        (res: Message) => {
          this.updateThreadPreview.emit(res);
          callBack(res, placeholderConversation.id);
        }, () => {
          placeholderConversation.status = 'error';
          this.toastService.error('Unable to send message, hit resend to try again');
        }, () => {
          this.cdr.markForCheck();
        });
    }
  }

   // Helper method to handle conversation updates
  private updateConversations(res: Message, placeholderId: string, mappedMessage: MessageExtension): void {
    mappedMessage.blobMeta?.map((item, index) => {
      mappedMessage.media[index].src = item.url;
    });

    if (this.conversation?.message.threadID === res.threadID) {
      const index = this.conversations?.findIndex((x) => x.id === placeholderId) ?? -1;
      if (index > -1 && this.conversations![index] && 'status' in this.conversations![index]) {
        this.conversations![index] = mappedMessage;
        this.scrollToBottom();
        this.cdr.detectChanges()
      }
    }
  }


  getUserSummaryById(userId: string): UserSummary {
    return this.conversation?.user?.find(x => x.id === userId) ?? {} as UserSummary;
  }

  getMessageThread(page: number, size: number): Observable<Message[]> {
    return this.messageService.getMessagesByThreadId(this.conversation!.message.threadID, page, size).pipe(
      switchMap((responses: Message[]) => {
        // Map each message to its media blob observables
        const enrichedMessages$: Observable<Message>[] = responses.map((message) => {
          if (message.blobMeta?.length) {
            // Process blob URLs for each blobMeta item
            const blobObservables = message.blobMeta.map((blobItem) =>
              this.getBlob(blobItem.url).pipe(
                map((blob) => {
                  //blobItem.extension = blobItem.extension.substring(1);
                  blobItem.blobModifiedUrl = URL.createObjectURL(blob as Blob); // Update the URL
                  return blobItem;
                })
              )
            );
  
            // Wait for all blob observables to complete and return the enriched message
            return forkJoin(blobObservables).pipe(
              map(() => message) // Return the original message after processing
            );
          } else {
            // No blobs to process, return the message as-is
            return of(message);
          }
        });
  
        // Combine all enriched messages into a single observable
        return forkJoin(enrichedMessages$);
      })
    );
  }

  getMessageDocumentType(blob: MessageExtension): string{
    if(blob.blobMeta){
      return blob.blobMeta[0].extension
    }
    return ''
  }

  getFileTypeRepresentation(fileType: string, mapType: 'image' | 'svg' | ''): string {
    if (!fileType) {
      return '';
    }
  
    const type = fileType.substring(1).toLowerCase();
    const map = mapType === 'image' ? this.imageMap : this.svgMap;
    const url = map[type] || map['txt']; // Fallback to "txt" if type is not found
  
    return `<img src="${url}" alt="${type}" />`;
  }

  mapToMedia(message: any | undefined): Media[] {
    if (!message) {
      return []; // Return an empty array if messageMedia is undefined or null
    }
  
    return message?.blobMeta.map((media: { url: string; blobModifiedUrl: string; blobType: string; alt: string; }) => ({
      id: media.url || '',
      src: media.blobModifiedUrl,
      type: media.blobType,
      alt: media.alt || 'Media item', 
    }));
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}

