<div class="media-preview-overlay">
    <div class="close" (click)="closePreview.emit()">
        <i [sch-icon]="'cancel'"></i>
    </div>

    <div class="media-preview mb-4" *ngIf="!showSpinner">
          <!-- Image Preview -->
          <ng-container *ngIf="mediaList.length > 0 && fileType === 'media'">
            <img [src]="mediaList[selectedMedia]" alt="Uploaded Image">
          </ng-container>

            <!-- Document Preview -->
            <div *ngIf="doc && fileType === 'document'">
              <ng-container>
                <div >
                    <div [innerHTML]="getPreviewImage(files[0])">
                    </div>
                </div>
              </ng-container>
              <div class="file-name-wrapper"><a [href]="doc" target="_blank">{{ filename }}</a></div>
            </div>
    </div>
    
    <div *ngIf="showSpinner" >
        <div class="spinner">
            <seech-spinner 
                [id]="'associates-spinner'">
            </seech-spinner>
        </div>
    </div>

    <div class="caption-row">
        <div class="text-input-wrapper">
            <textarea class="text-input" 
            id="caption"
            placeholder="Add caption" 
            [(ngModel)]="messageBody"
            (keydown.enter)="$event.preventDefault()"
            (keyup.enter)="handleKeyUp($event)"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="5"></textarea>
        </div>

        <div class="send" (click)="sendMessageWithMedia()">
            <i [sch-icon]="'send'"></i>
        </div>
    </div>

    <div class="img-tray-container">
      <div 
        class="img" 
        (click)="!showSpinner && selectFile()" 
        [class.disabled]="showSpinner">
        <i [sch-icon]="'plus'"></i>
      </div>
      <div class="img-tray">
          <ng-container *ngFor="let img of mediaList; let i = index">
              <div class="img" (click)="selectedMedia = i">
                  <img [src]="img" alt="">
                  <div class="remove-icon" (click)="removeMedia(i)">
                      <i [sch-icon]="'close'"></i>
                  </div>
              </div>
          </ng-container>
      </div>
    </div>
</div>
