/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { Session, Token, User } from '@profindar/shared-ng';
import { AuthService } from '@seech/auth-ng';
import { RealTimeCommunicationService } from '@seech/core-ng';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class RealtimeService {

  public subject = new Subject<any>();

  constructor(
    private realTimeCommunicationService: RealTimeCommunicationService, private authService: AuthService<User, Session, Token>) {}
    
    public startConnection(){
      const token = this.authService.getCurrentToken()?.access_token;
      if (token) this.realTimeCommunicationService.connect(token);
    }

    public stopConnection(){
      this.realTimeCommunicationService.disconnect()
    }
    
    public startListener(methodName: string, _channel: string){
      this.startConnection();
      this.realTimeCommunicationService.addListener(methodName, (channel: string, event: any) => {
        if(_channel == channel)
          this.subject.next(event)
      });
    }

    public removeChatListener(methodName: string) {
      this.realTimeCommunicationService.removeListener(methodName);
    }

    public sendMessage(methodName: string, user: string, message: string) {
      this.realTimeCommunicationService.send(methodName, user, message);
    }
}
